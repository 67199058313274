require('../css/app.pcss');

import Form from './components/Form'
import { floatingFormLabels } from 'floating-form-labels/dist/floatingFormLabels.min.js'

const loginForm = new Form(
  "[data-form='login-form']", {
  settings: {
    debug: true,
    successCriteriaKey: 'success',
    useErrorKey: true,
    ignoreSuccessCallout: true,
    clearLoadingAfterSuccess: false,
  },
  contentSettings: {
    successMessage: 'Login successful, redirecting..',
  },
  onSuccess: function(response) {
    $(location).attr('href', response.returnUrl);
  },
  onError: function(response) {
    $("[data-form-field='password']").find('[data-field-input]').val('')
  }
})

$(document).ready(function() {

  var data = {
    action: 'cnvs/dynamic/load-dynamic-content',
  }

   $.ajax({
    type: 'GET',
      url: '/',
      dataType: 'json',
      data: data
  }).done(result => {
    $('body').append(`<script type='text/javascript'>
      window.csrfTokenName = 'CSRF_TOKEN';
      window.csrfTokenValue = '${result.code}';
      window.canPlaceOrders = '${result.canPlaceOrders}';
      window.accountType = '${result.accountType}';
    </script>`);
    $("input[name='CSRF_TOKEN']").val(result.code)
  })
})


$('.loginPage__form').find('.inputField').floatingFormLabels({
  label: 'inputField__label',
  floatedClass: 'inputField--active',
  formElements: 'input, textarea, select'
});

